<template>
    <a-card>
        <a-page-header
            :title='"修改车系"'
        />

        <a-form-model
            ref="form"
            :model="form"
            v-bind='layout'
        >
            <a-form-model-item label="广告组">
                {{ adGroupName || '-' }}
            </a-form-model-item>
            <a-form-model-item label="广告计划">
                {{ adPlanName || '-' }}
            </a-form-model-item>
            <a-form-model-item label="站点标题">
                {{ siteTitle || '-' }}
            </a-form-model-item>
            <a-form-model-item label="选择车系">
                <a-select
                    v-model="form.preferCarTrainId"
                    placeholder="请输入"
                    allow-clear
                    show-search
                    option-filter-prop="children"
                    @change="onChangeSeries"
                >
                    <a-select-option
                        v-for="item in seriesList"
                        :key="item.id"
                        :name="item.name"
                    >
                        {{ item.name }}
                    </a-select-option>
                </a-select>
            </a-form-model-item>
            <a-form-model-item label=" " :colon="false">
                <a-space>
                    <base-button
                        :title="'取消'"
                        @onClickBtn="handleBack"
                    ></base-button>
                    <base-button
                        :type="'primary'"
                        :title="'提交'"
                        :loading="isSubmit"
                        :disabled="isSubmit"
                        @onClickBtn="handleSubmit"
                    ></base-button>
                </a-space>
            </a-form-model-item>
        </a-form-model>
    </a-card>
</template>

<script>
export default {
    data() {
        return {
            // id: this.$route.query.id,
            adGroupName: this.$route.query.adGroupName,
            adPlanName: this.$route.query.adPlanName,
            siteTitle: this.$route.query.siteTitle,
            layout: {
                labelCol: { span: 6 },
                wrapperCol: { span: 11 }
            },
            form: {
                id: this.$route.query.id,
                preferCarTrain: '',
                preferCarTrainId: undefined
            },
            isSubmit: false,
            seriesList: []
        }
    },
    created() {
        this.getSeriesList()
    },
    methods: {
        getSeriesList() {
            // this.$api.core.pphtManage.getSeriesList().then(res => {
            //     if(Array.isArray(res)) {
            //         this.seriesList = res || []
            //     } else {
            //         this.$message.error(`获取车系列表失败, ${res.message}`)
            //     }
            // })
            let params = {
                principalIds: [],
                status: [1, 4]
            }
            this.$api.base_api.getPrincipalSeriesList(params).then(res => {
                if(res.code == 200) {
                    this.seriesList = res.data
                } else {
                    this.seriesList = []
                    console.error(`获取车系失败，${res}`)
                }
            })
        },
        onChangeSeries(val, option) {
            this.form.preferCarTrain = val ? option.data.attrs.name : ''
        },
        handleBack() {
            let that = this
            this.$confirm({
                content: h => <div><span style="color: red; display: block;">表单数据还未保存</span>离开数据将会丢失，是否确认离开？
                </div>,
                okText: '确定',
                cancelText: '取消',
                onOk () {
                    that.$router.push({
                        path: '/clueManage'
                    })
                }
            })
        },
        handleSubmit() {
            this.$refs.form.validate((valid, obj) => {
                if (valid) {
                    this.isSubmit = true
                    this.$api.core.pphtManage.submitAdvertiserClueCarDetail( this.form ).then(res => {
                        this.isSubmit = false
                        if(res.code == 200) {
                            this.$message.success('提交成功')
                            this.$router.push({
                                path: '/clueManage'
                            })
                        } else {
                            this.$message.error(`提交失败，${res.message}`)
                        }
                    })
                }
            })
        },
    }
}
</script>

<style scoped lang="less">
/deep/ .ant-page-header {
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 40px;
}
</style>